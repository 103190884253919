<template>
  <v-container>
    <v-card>
      <v-card-title>
        <div class="headline">{{ $t("title_change_password") }}</div>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="update">
            <v-row no-gutters class="text-center">
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50|verify_password"
                  :name="$t('new_password')"
                  vid="new_password"
                >
                  <v-text-field
                    type="password"
                    :label="$t('new_password')"
                    v-model="new_password"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                  <span class="post-label"
                    >※ 英大文字、英小文字、数字をすべて使用してください</span
                  >
                  <div class="v-messages theme--light error--text">
                    {{ errors ? errors[0] : "" }}
                  </div>
                </validation-provider>
              </v-col>

              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:50|password_confirmed:new_password"
                  :name="$t('confirm_password')"
                >
                  <v-text-field
                    type="password"
                    :label="$t('confirm_password')"
                    v-model="confirm_password"
                    :error-messages="errors"
                    :error="errors.length !== 0"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col class="py-4" cols="12">
                <v-btn type="submit" :disabled="invalid" color="primary" block>
                  {{ $t("reset_password") }}
                </v-btn>
              </v-col>

              <v-col cols="12" class="text-left d-none">
                <v-card class="info-card" flat>
                  <v-card-text>
                    <div class="mb-2">
                      パスワードは下記の設定ルールから4つ以上満たしてください。
                    </div>
                    <ul>
                      <li>
                        8-20 文字（必須）
                        <v-icon
                          :color="containsCorrectLength ? 'success' : 'error'"
                          size="16"
                        >
                          {{
                            containsCorrectLength ? "mdi-check" : "mdi-close"
                          }}
                        </v-icon>
                      </li>
                      <li>
                        大文字（A-Z）を含む
                        <v-icon
                          :color="containsUppercase ? 'success' : 'error'"
                          size="16"
                        >
                          {{ containsUppercase ? "mdi-check" : "mdi-close" }}
                        </v-icon>
                      </li>
                      <li>
                        小文字（a-z）を含む
                        <v-icon
                          :color="containsLowercase ? 'success' : 'error'"
                          size="16"
                        >
                          {{ containsLowercase ? "mdi-check" : "mdi-close" }}
                        </v-icon>
                      </li>
                      <li>
                        数字（0-9）を含む
                        <v-icon
                          :color="containsNumber ? 'success' : 'error'"
                          size="16"
                        >
                          {{ containsNumber ? "mdi-check" : "mdi-close" }}
                        </v-icon>
                      </li>
                      <!-- <li>
                        記号を含む: ~`!@#$%^&*()_-+={[}]|\:;"'&lt;,>.?/
                        <v-icon
                          :color="containsSymbol ? 'success' : 'error'"
                          size="16"
                        >
                          {{ containsSymbol ? "mdi-check" : "mdi-close" }}
                        </v-icon>
                      </li> -->
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" class="py-4 d-none">
                <v-row>
                  <v-col v-if="passwordStrength >= 1" cols="4">
                    <v-btn
                      x-small
                      rounded
                      block
                      class="label elevation-0 password password-weak"
                    ></v-btn>
                  </v-col>
                  <v-col v-if="passwordStrength >= 2" cols="4">
                    <v-btn
                      x-small
                      rounded
                      block
                      class="label elevation-0 password password-medium"
                    ></v-btn>
                  </v-col>
                  <v-col v-if="passwordStrength >= 3" cols="4">
                    <v-btn
                      x-small
                      rounded
                      block
                      class="label elevation-0 password password-strong"
                    ></v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                v-if="passwordStrength >= 1"
                cols="12"
                class="text-center d-none"
              >
                <span :class="`password-${passwordStrengthText}-text`">
                  {{ $t(`password_strength_text_${passwordStrengthText}`) }}.
                </span>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ChangePasswordPage",
  data() {
    return {
      new_password: "",
      confirm_password: ""
    };
  },
  computed: {
    passwordStrength() {
      if (!this.new_password) return 0;
      let strong = RegExp(
        "^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$",
        "g"
      );
      let medium = new RegExp(
        "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
      );
      if (strong.test(this.new_password)) {
        return 3;
      } else if (medium.test(this.new_password)) {
        return 2;
      } else {
        return 1;
      }
    },
    passwordStrengthText() {
      return this.passwordStrength === 3
        ? "strong"
        : this.passwordStrength === 2
        ? "medium"
        : "weak";
    },
    containsCorrectLength() {
      return this.new_password.match(/(^.{8,20}$)/);
    },
    containsUppercase() {
      return this.new_password.match(/([A-Z])/);
    },
    containsLowercase() {
      return this.new_password.match(/([a-z])/);
    },
    containsNumber() {
      return this.new_password.match(/([0-9])/);
    },
    containsSymbol() {
      return this.new_password.match(/[^ A-Za-z0-9]/);
    }
  },
  methods: {
    update() {
      this.$refs.observer.validate();

      this.loading = true;

      let data = {
        new_password: this.new_password,
        new_password_confirmation: this.confirm_password
      };

      this.$store.dispatch("USER_CHANGE_PASSWORD", data).then(result => {
        if (result.status === 200) {
          this.$refs.observer.reset();

          this.$store.commit("showAlert", {
            text: this.$t("password_change_success_message"),
            successStatus: true
          });
        }
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.info-card {
  background-color: #f9f5e9;
  box-shadow: unset !important;

  .v-card__text {
    color: #534829 !important;
  }
}
.password {
  border-radius: 3px !important;
  height: 12px !important;

  &-weak {
    background-color: #e95f5f !important;

    &-text {
      background-color: unset;
      color: #e95f5f;
    }
  }

  &-medium {
    &-inactive {
      background-color: rgba(255, 173, 50, 0.5) !important;
    }
    background-color: #ffad32 !important;

    &-text {
      background-color: unset;
      color: #ffad32;
    }
  }

  &-strong {
    &-inactive {
      background-color: rgba(12, 163, 9, 0.5) !important;
    }
    background-color: rgb(12, 163, 9) !important;

    &-text {
      background-color: unset;
      color: #0ca309;
    }
  }
}
::v-deep .v-text-field__details {
  display: none !important;
  min-height: 0px;
  margin-bottom: 0px;
}
::v-deep .v-messages.theme--light.error--text {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
::v-deep .post-label {
  font-size: 12px;
  position: relative;
  color: #666666;
}
::v-deep .v-messages__message {
  line-height: 1.3 !important;
}
</style>
